import {
  ApplicationPayload,
  ReduxAction,
  ReduxActionErrorTypes,
  ReduxActionTypes,
} from "@appsmith/constants/ReduxActionConstants";
import { getCurrentWorkspaceId } from "@appsmith/selectors/workspaceSelectors";
import { builderURL } from "RouteBuilder";
import {
  fetchApplication,
  showReconnectDatasourceModal,
} from "actions/applicationActions";
import { fetchDatasources } from "actions/datasourceActions";
import { fetchJSCollections } from "actions/jsActionActions";
import { fetchAllPageEntityCompletion, saveLayout } from "actions/pageActions";
import {
  executePageLoadActions,
  fetchActions,
} from "actions/pluginActionActions";
import { fetchPluginFormConfigs } from "actions/pluginActions";
import {
  getAllTemplates,
  setTemplateNotificationSeenAction,
  showTemplatesModal,
} from "actions/templateActions";
import TemplatesAPI, {
  FetchTemplateResponse,
  ImportTemplateResponse,
} from "api/TemplatesApi";
import { Toaster, Variant } from "design-system";
import { APP_MODE } from "entities/App";
import { all, call, put, select, take, takeEvery } from "redux-saga/effects";
import { getCurrentApplicationId } from "selectors/editorSelectors";
import history from "utils/history";
import {
  getTemplateNotificationSeen,
  setTemplateNotificationSeen,
} from "utils/storage";
import { getDefaultPageId } from "./ApplicationSagas";
import { validateResponse } from "./ErrorSagas";
import { failFastApiCalls } from "./InitSagas";
import { getAllPageIds } from "./selectors";
const templates = {
  userPermissions: [],
  widgets: [
    "IMAGE_WIDGET",
    "MULTI_SELECT_TREE_WIDGET",
    "FILE_PICKER_WIDGET_V2",
    "STATBOX_WIDGET",
    "BUTTON_WIDGET",
    "BUTTON_GROUP_WIDGET",
    "MAP_CHART_WIDGET",
    "VIDEO_WIDGET",
    "CURRENCY_INPUT_WIDGET",
    "MENU_BUTTON_WIDGET",
    "CHECKBOX_WIDGET",
    "PROGRESSBAR_WIDGET",
    "DATE_PICKER_WIDGET2",
    "MAP_WIDGET",
    "CONTAINER_WIDGET",
    "ICON_BUTTON_WIDGET",
    "IFRAME_WIDGET",
    "RADIO_GROUP_WIDGET",
    "RATE_WIDGET",
    "RICH_TEXT_EDITOR_WIDGET",
    "LIST_WIDGET_V2",
    "MULTI_SELECT_WIDGET_V2",
    "AUDIO_RECORDER_WIDGET",
    "AUDIO_WIDGET",
    "FORM_WIDGET",
    "MODAL_WIDGET",
    "INPUT_WIDGET_V2",
    "TABLE_WIDGET_V2",
    "DIVIDER_WIDGET",
    "SELECT_WIDGET",
    "CHART_WIDGET",
    "JSON_FORM_WIDGET",
    "LIST_WIDGET",
    "PHONE_INPUT_WIDGET",
    "TEXT_WIDGET",
    "SWITCH_WIDGET",
    "TABS_WIDGET",
    "CAMERA_WIDGET",
    "CHECKBOX_GROUP_WIDGET",
    "DOCUMENT_VIEWER_WIDGET",
    "SWITCH_GROUP_WIDGET",
    "CIRCULAR_PROGRESS_WIDGET",
    "TABLE_WIDGET",
    "SINGLE_SELECT_TREE_WIDGET",
  ],
  functions: [
    "Other",
    "Technology",
    "Energy",
    "Sample apps",
    "Marketing",
    "Utilities",
    "WhatsApp",
    "Google Maps",
    "Real Estate",
    "Consumer goods",
    "Agriculture",
    "Communication Services",
    "All",
    "Client Side Filtering",
    "E-Commerce",
    "Slack",
    "Industrials",
    "Operations",
    "Server Side Pagination",
    "Customer Support",
    "textile",
    "Materials",
    "Start-up",
    "Services",
    "Businesses",
    "Financials",
    "Consumer Discretionary",
    "Human Resources (HR)",
    "Health Care",
    "Sales",
  ],
  useCases: [
    "Compliance",
    "Approval App",
    "Legal",
    "Order Management",
    "Database GUI",
    "Service Desk",
    "Productivity",
    "Project Management",
    "Remote work",
    "Support",
    "Marketing",
    "Human Resources (HR)",
    "Tracking tool",
    "Workflow Automation",
    "Sales",
    "Dashboard",
    "Public Relations (PR)",
    "Information Technology (IT)",
    "Product, design, and UX",
    "Finance",
    "Solutions",
    "Software Development",
    "Communications",
    "Personal",
    "B2C",
  ],
  datasources: [
    "redis-plugin",
    "mssql-plugin",
    "firestore-plugin",
    "smtp-plugin",
    "mongo-plugin",
    "snowflake-plugin",
    "redshift-plugin",
    "postgres-plugin",
    "rapidapi-plugin",
    "amazons3-plugin",
    "google-sheets-plugin",
    "mysql-plugin",
    "dynamo-plugin",
    "elasticsearch-plugin",
    "twilio",
    "restapi-plugin",
    "js-plugin",
    "arangodb-plugin",
    "saas-plugin",
  ],
  new: true,
};
function* getAllTemplatesSaga() {
  try {
    const response: FetchTemplateResponse = yield call(
      TemplatesAPI.getAllTemplates,
    );
    const isValid: boolean = yield validateResponse(response);
    if (isValid) {
      yield put({
        type: ReduxActionTypes.GET_ALL_TEMPLATES_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.GET_ALL_TEMPLATES_ERROR,
      payload: {
        error,
      },
    });
  }
}

function* importTemplateToWorkspaceSaga(
  action: ReduxAction<{ templateId: string; workspaceId: string }>,
) {
  try {
    const response: ImportTemplateResponse = yield call(
      TemplatesAPI.importTemplate,
      action.payload.templateId,
      action.payload.workspaceId,
    );
    const isValid: boolean = yield validateResponse(response);
    if (isValid) {
      const application: ApplicationPayload = {
        ...response.data.application,
        defaultPageId: getDefaultPageId(
          response.data.application.pages,
        ) as string,
      };
      yield put({
        type: ReduxActionTypes.IMPORT_TEMPLATE_TO_WORKSPACE_SUCCESS,
        payload: response.data.application,
      });

      if (response.data.isPartialImport) {
        yield put(
          showReconnectDatasourceModal({
            application: response.data.application,
            unConfiguredDatasourceList:
              response.data.unConfiguredDatasourceList,
            workspaceId: action.payload.workspaceId,
          }),
        );
      } else {
        const pageURL = builderURL({
          pageId: application.defaultPageId,
        });
        history.push(pageURL);
      }
      yield put(getAllTemplates());
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.IMPORT_TEMPLATE_TO_WORKSPACE_ERROR,
      payload: {
        error,
      },
    });
  }
}

function* getSimilarTemplatesSaga(action: ReduxAction<string>) {
  try {
    const response: FetchTemplateResponse = yield call(
      TemplatesAPI.getSimilarTemplates,
      action.payload,
    );
    const isValid: boolean = yield validateResponse(response);
    if (isValid) {
      yield put({
        type: ReduxActionTypes.GET_SIMILAR_TEMPLATES_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.GET_SIMILAR_TEMPLATES_ERROR,
      payload: {
        error,
      },
    });
  }
}

function* setTemplateNotificationSeenSaga(action: ReduxAction<boolean>) {
  yield setTemplateNotificationSeen(action.payload);
}

function* getTemplateNotificationSeenSaga() {
  const showTemplateNotification: unknown = yield getTemplateNotificationSeen();

  if (showTemplateNotification) {
    yield put(setTemplateNotificationSeenAction(true));
  } else {
    yield put(setTemplateNotificationSeenAction(false));
  }
}

function* getTemplateSaga(action: ReduxAction<string>) {
  try {
    const response: FetchTemplateResponse = yield call(
      TemplatesAPI.getTemplateInformation,
      action.payload,
    );
    const isValid: boolean = yield validateResponse(response);
    if (isValid) {
      yield put({
        type: ReduxActionTypes.GET_TEMPLATE_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.GET_TEMPLATE_ERROR,
      payload: {
        error,
      },
    });
  }
}

function* postPageAdditionSaga(applicationId: string) {
  const afterActionsFetch: boolean = yield failFastApiCalls(
    [
      fetchActions({ applicationId }, []),
      fetchJSCollections({ applicationId }),
      fetchDatasources(),
    ],
    [
      ReduxActionTypes.FETCH_ACTIONS_SUCCESS,
      ReduxActionTypes.FETCH_JS_ACTIONS_SUCCESS,
      ReduxActionTypes.FETCH_DATASOURCES_SUCCESS,
    ],
    [
      ReduxActionErrorTypes.FETCH_ACTIONS_ERROR,
      ReduxActionErrorTypes.FETCH_JS_ACTIONS_ERROR,
      ReduxActionErrorTypes.FETCH_DATASOURCES_ERROR,
    ],
  );

  if (!afterActionsFetch) {
    throw new Error("Failed importing template");
  }

  const afterPluginFormsFetch: boolean = yield failFastApiCalls(
    [fetchPluginFormConfigs()],
    [ReduxActionTypes.FETCH_PLUGIN_FORM_CONFIGS_SUCCESS],
    [ReduxActionErrorTypes.FETCH_PLUGIN_FORM_CONFIGS_ERROR],
  );

  if (!afterPluginFormsFetch) {
    throw new Error("Failed importing template");
  }

  yield put(fetchAllPageEntityCompletion([executePageLoadActions()]));
}

function* forkTemplateToApplicationSaga(
  action: ReduxAction<{
    templateId: string;
    templateName: string;
    pageNames?: string[];
  }>,
) {
  try {
    const pagesToImport = action.payload.pageNames
      ? action.payload.pageNames
      : undefined;
    const applicationId: string = yield select(getCurrentApplicationId);
    const workspaceId: string = yield select(getCurrentWorkspaceId);
    const response: ImportTemplateResponse = yield call(
      TemplatesAPI.importTemplateToApplication,
      action.payload.templateId,
      applicationId,
      workspaceId,
      pagesToImport,
    );
    // To fetch the new set of pages after merging the template into the existing application
    yield put(
      fetchApplication({
        mode: APP_MODE.EDIT,
        applicationId,
      }),
    );
    const isValid: boolean = yield validateResponse(response);

    if (isValid) {
      yield call(postPageAdditionSaga, applicationId);
      const pages: string[] = yield select(getAllPageIds);

      if (response.data.isPartialImport) {
        yield put(
          showReconnectDatasourceModal({
            application: response.data.application,
            unConfiguredDatasourceList:
              response.data.unConfiguredDatasourceList,
            workspaceId,
            pageId: pages[0],
          }),
        );
      }
      history.push(
        builderURL({
          pageId: pages[0],
        }),
      );
      yield put(showTemplatesModal(false));

      yield take(ReduxActionTypes.UPDATE_CANVAS_STRUCTURE);
      yield put(saveLayout());
      yield put({
        type: ReduxActionTypes.IMPORT_TEMPLATE_TO_APPLICATION_SUCCESS,
        payload: response.data.application,
      });
      yield put(getAllTemplates());

      Toaster.show({
        text: `Pages from '${action.payload.templateName}' template added successfully`,
        variant: Variant.success,
      });
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.IMPORT_TEMPLATE_TO_APPLICATION_ERROR,
      payload: {
        error,
      },
    });
  }
}

function* getTemplateFiltersSaga() {
  try {
    // const response: TemplateFiltersResponse = yield call(
    //   TemplatesAPI.getTemplateFilters,
    // );
    // const isValid: boolean = yield validateResponse(response);
    // if (isValid) {
    yield put({
      type: ReduxActionTypes.GET_TEMPLATE_FILTERS_SUCCESS,
      payload: templates,
    });
    // }
  } catch (e) {
    yield put({
      type: ReduxActionErrorTypes.GET_TEMPLATE_FILTERS_ERROR,
      payload: {
        e,
      },
    });
  }
}

export default function* watchActionSagas() {
  yield all([
    takeEvery(ReduxActionTypes.GET_ALL_TEMPLATES_INIT, getAllTemplatesSaga),
    takeEvery(ReduxActionTypes.GET_TEMPLATE_INIT, getTemplateSaga),
    takeEvery(
      ReduxActionTypes.GET_SIMILAR_TEMPLATES_INIT,
      getSimilarTemplatesSaga,
    ),
    takeEvery(
      ReduxActionTypes.IMPORT_TEMPLATE_TO_WORKSPACE_INIT,
      importTemplateToWorkspaceSaga,
    ),
    takeEvery(
      ReduxActionTypes.GET_TEMPLATE_NOTIFICATION_SEEN,
      getTemplateNotificationSeenSaga,
    ),
    takeEvery(
      ReduxActionTypes.SET_TEMPLATE_NOTIFICATION_SEEN,
      setTemplateNotificationSeenSaga,
    ),
    takeEvery(
      ReduxActionTypes.IMPORT_TEMPLATE_TO_APPLICATION_INIT,
      forkTemplateToApplicationSaga,
    ),
    takeEvery(
      ReduxActionTypes.GET_TEMPLATE_FILTERS_INIT,
      getTemplateFiltersSaga,
    ),
  ]);
}
