import React from "react";

export const DragSourceAnimation: any = {
  light: () => (
    <svg
      viewBox="0 0 1024 1024"
      dangerouslySetInnerHTML={{
        __html: `
      <style>
@keyframes helper-drag-rec_w { 0% { width: 210px; } 54.5455% { width: 210px; animation-timing-function: cubic-bezier(0.904,0,0.726,0.668); } 66.6667% { width: 540px; animation-timing-function: cubic-bezier(0.214,0.499,0.52,0.867); } 72.7273% { width: 520px; } 100% { width: 520px; } }
@keyframes helper-drag-rec_h { 0% { height: 100px; } 54.5455% { height: 100px; animation-timing-function: cubic-bezier(0.904,0,0.726,0.668); } 66.6667% { height: 560px; animation-timing-function: cubic-bezier(0.214,0.499,0.52,0.867); } 72.7273% { height: 540px; } 100% { height: 540px; } }
@keyframes helper-drag-rec_mo { 0% { offset-distance: 0%; } 33.3333% { offset-distance: 0.12%; animation-timing-function: cubic-bezier(0.62,0.079,0.616,1); } 54.5455% { offset-distance: 64.297%; animation-timing-function: cubic-bezier(0.904,0,0.726,0.668); } 66.6667% { offset-distance: 98.331%; animation-timing-function: cubic-bezier(0.214,0.499,0.52,0.867); } 72.7273% { offset-distance: 100%; } 100% { offset-distance: 100%; } }
@keyframes helper-drag-mouse_mo { 0% { offset-distance: 0%; animation-timing-function: cubic-bezier(0.69,0.145,0.37,1); } 18.1818% { offset-distance: 37.49%; } 24.2424% { offset-distance: 38.651%; } 33.3333% { offset-distance: 38.651%; animation-timing-function: cubic-bezier(0.62,0.079,0.616,1); } 54.5455% { offset-distance: 100%; } 100% { offset-distance: 100%; } }
@keyframes helper-drag-boomrightup_o { 0% { opacity: 0; } 66.6667% { opacity: 0; } 69.697% { opacity: 1; } 75.7576% { opacity: 1; } 78.7879% { opacity: 0; } 100% { opacity: 0; } }
@keyframes a0_d { 0% { d: path('M1,0L3,0C3.55228,0,4,0.447715,4,1L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,1C0,0.447715,0.447715,0,1,0Z'); } 66.6667% { d: path('M1,0L3,0C3.55228,0,4,0.447715,4,1L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,1C0,0.447715,0.447715,0,1,0Z'); animation-timing-function: cubic-bezier(0.455,0.03,0.515,0.955); } 72.7273% { d: path('M1,-15L3,-15C3.55228,-15,4,-14.5523,4,-14L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,-14C0,-14.5523,0.447715,-15,1,-15Z'); animation-timing-function: cubic-bezier(0.455,0.03,0.515,0.955); } 78.7879% { d: path('M1,-10L3,-10C3.55228,-10,4,-9.55228,4,-9L4,-7C4,-6.44772,3.55228,-6,3,-6L1,-6C0.447715,-6,0,-6.44772,0,-7L0,-9C0,-9.55228,0.447715,-10,1,-10Z'); } 100% { d: path('M1,-10L3,-10C3.55228,-10,4,-9.55228,4,-9L4,-7C4,-6.44772,3.55228,-6,3,-6L1,-6C0.447715,-6,0,-6.44772,0,-7L0,-9C0,-9.55228,0.447715,-10,1,-10Z'); } }
@keyframes a1_d { 0% { d: path('M1,0L3,0C3.55228,0,4,0.447715,4,1L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,1C0,0.447715,0.447715,0,1,0Z'); } 66.6667% { d: path('M1,0L3,0C3.55228,0,4,0.447715,4,1L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,1C0,0.447715,0.447715,0,1,0Z'); animation-timing-function: cubic-bezier(0.455,0.03,0.515,0.955); } 72.7273% { d: path('M1,-15L3,-15C3.55228,-15,4,-14.5523,4,-14L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,-14C0,-14.5523,0.447715,-15,1,-15Z'); animation-timing-function: cubic-bezier(0.455,0.03,0.515,0.955); } 78.7879% { d: path('M1,-10L3,-10C3.55228,-10,4,-9.55228,4,-9L4,-7C4,-6.44772,3.55228,-6,3,-6L1,-6C0.447715,-6,0,-6.44772,0,-7L0,-9C0,-9.55228,0.447715,-10,1,-10Z'); } 100% { d: path('M1,-10L3,-10C3.55228,-10,4,-9.55228,4,-9L4,-7C4,-6.44772,3.55228,-6,3,-6L1,-6C0.447715,-6,0,-6.44772,0,-7L0,-9C0,-9.55228,0.447715,-10,1,-10Z'); } }
@keyframes a2_d { 0% { d: path('M1,0L3,0C3.55228,0,4,0.447715,4,1L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,1C0,0.447715,0.447715,0,1,0Z'); } 66.6667% { d: path('M1,0L3,0C3.55228,0,4,0.447715,4,1L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,1C0,0.447715,0.447715,0,1,0Z'); animation-timing-function: cubic-bezier(0.455,0.03,0.515,0.955); } 72.7273% { d: path('M1,-15L3,-15C3.55228,-15,4,-14.5523,4,-14L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,-14C0,-14.5523,0.447715,-15,1,-15Z'); animation-timing-function: cubic-bezier(0.455,0.03,0.515,0.955); } 78.7879% { d: path('M1,-10L3,-10C3.55228,-10,4,-9.55228,4,-9L4,-7C4,-6.44772,3.55228,-6,3,-6L1,-6C0.447715,-6,0,-6.44772,0,-7L0,-9C0,-9.55228,0.447715,-10,1,-10Z'); } 100% { d: path('M1,-10L3,-10C3.55228,-10,4,-9.55228,4,-9L4,-7C4,-6.44772,3.55228,-6,3,-6L1,-6C0.447715,-6,0,-6.44772,0,-7L0,-9C0,-9.55228,0.447715,-10,1,-10Z'); } }
@keyframes helper-drag-boomleftup_o { 0% { opacity: 0; } 66.6667% { opacity: 0; } 69.697% { opacity: 1; } 75.7576% { opacity: 1; } 78.7879% { opacity: 0; } 100% { opacity: 0; } }
@keyframes a3_d { 0% { d: path('M1,0L3,0C3.55228,0,4,0.447715,4,1L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,1C0,0.447715,0.447715,0,1,0Z'); } 66.6667% { d: path('M1,0L3,0C3.55228,0,4,0.447715,4,1L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,1C0,0.447715,0.447715,0,1,0Z'); animation-timing-function: cubic-bezier(0.455,0.03,0.515,0.955); } 72.7273% { d: path('M1,-15L3,-15C3.55228,-15,4,-14.5523,4,-14L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,-14C0,-14.5523,0.447715,-15,1,-15Z'); animation-timing-function: cubic-bezier(0.455,0.03,0.515,0.955); } 78.7879% { d: path('M1,-10L3,-10C3.55228,-10,4,-9.55228,4,-9L4,-7C4,-6.44772,3.55228,-6,3,-6L1,-6C0.447715,-6,0,-6.44772,0,-7L0,-9C0,-9.55228,0.447715,-10,1,-10Z'); } 100% { d: path('M1,-10L3,-10C3.55228,-10,4,-9.55228,4,-9L4,-7C4,-6.44772,3.55228,-6,3,-6L1,-6C0.447715,-6,0,-6.44772,0,-7L0,-9C0,-9.55228,0.447715,-10,1,-10Z'); } }
@keyframes a4_d { 0% { d: path('M1,0L3,0C3.55228,0,4,0.447715,4,1L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,1C0,0.447715,0.447715,0,1,0Z'); } 66.6667% { d: path('M1,0L3,0C3.55228,0,4,0.447715,4,1L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,1C0,0.447715,0.447715,0,1,0Z'); animation-timing-function: cubic-bezier(0.455,0.03,0.515,0.955); } 72.7273% { d: path('M1,-15L3,-15C3.55228,-15,4,-14.5523,4,-14L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,-14C0,-14.5523,0.447715,-15,1,-15Z'); animation-timing-function: cubic-bezier(0.455,0.03,0.515,0.955); } 78.7879% { d: path('M1,-10L3,-10C3.55228,-10,4,-9.55228,4,-9L4,-7C4,-6.44772,3.55228,-6,3,-6L1,-6C0.447715,-6,0,-6.44772,0,-7L0,-9C0,-9.55228,0.447715,-10,1,-10Z'); } 100% { d: path('M1,-10L3,-10C3.55228,-10,4,-9.55228,4,-9L4,-7C4,-6.44772,3.55228,-6,3,-6L1,-6C0.447715,-6,0,-6.44772,0,-7L0,-9C0,-9.55228,0.447715,-10,1,-10Z'); } }
@keyframes a5_d { 0% { d: path('M1,0L3,0C3.55228,0,4,0.447715,4,1L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,1C0,0.447715,0.447715,0,1,0Z'); } 66.6667% { d: path('M1,0L3,0C3.55228,0,4,0.447715,4,1L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,1C0,0.447715,0.447715,0,1,0Z'); animation-timing-function: cubic-bezier(0.455,0.03,0.515,0.955); } 72.7273% { d: path('M1,-15L3,-15C3.55228,-15,4,-14.5523,4,-14L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,-14C0,-14.5523,0.447715,-15,1,-15Z'); animation-timing-function: cubic-bezier(0.455,0.03,0.515,0.955); } 78.7879% { d: path('M1,-10L3,-10C3.55228,-10,4,-9.55228,4,-9L4,-7C4,-6.44772,3.55228,-6,3,-6L1,-6C0.447715,-6,0,-6.44772,0,-7L0,-9C0,-9.55228,0.447715,-10,1,-10Z'); } 100% { d: path('M1,-10L3,-10C3.55228,-10,4,-9.55228,4,-9L4,-7C4,-6.44772,3.55228,-6,3,-6L1,-6C0.447715,-6,0,-6.44772,0,-7L0,-9C0,-9.55228,0.447715,-10,1,-10Z'); } }
@keyframes helper-drag-boomrightbottom_o { 0% { opacity: 0; } 66.6667% { opacity: 0; } 69.697% { opacity: 1; } 75.7576% { opacity: 1; } 78.7879% { opacity: 0; } 100% { opacity: 0; } }
@keyframes a6_d { 0% { d: path('M1,0L3,0C3.55228,0,4,0.447715,4,1L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,1C0,0.447715,0.447715,0,1,0Z'); } 66.6667% { d: path('M1,0L3,0C3.55228,0,4,0.447715,4,1L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,1C0,0.447715,0.447715,0,1,0Z'); animation-timing-function: cubic-bezier(0.455,0.03,0.515,0.955); } 72.7273% { d: path('M1,-15L3,-15C3.55228,-15,4,-14.5523,4,-14L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,-14C0,-14.5523,0.447715,-15,1,-15Z'); animation-timing-function: cubic-bezier(0.455,0.03,0.515,0.955); } 78.7879% { d: path('M1,-10L3,-10C3.55228,-10,4,-9.55228,4,-9L4,-7C4,-6.44772,3.55228,-6,3,-6L1,-6C0.447715,-6,0,-6.44772,0,-7L0,-9C0,-9.55228,0.447715,-10,1,-10Z'); } 100% { d: path('M1,-10L3,-10C3.55228,-10,4,-9.55228,4,-9L4,-7C4,-6.44772,3.55228,-6,3,-6L1,-6C0.447715,-6,0,-6.44772,0,-7L0,-9C0,-9.55228,0.447715,-10,1,-10Z'); } }
@keyframes a7_d { 0% { d: path('M1,0L3,0C3.55228,0,4,0.447715,4,1L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,1C0,0.447715,0.447715,0,1,0Z'); } 66.6667% { d: path('M1,0L3,0C3.55228,0,4,0.447715,4,1L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,1C0,0.447715,0.447715,0,1,0Z'); animation-timing-function: cubic-bezier(0.455,0.03,0.515,0.955); } 72.7273% { d: path('M1,-15L3,-15C3.55228,-15,4,-14.5523,4,-14L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,-14C0,-14.5523,0.447715,-15,1,-15Z'); animation-timing-function: cubic-bezier(0.455,0.03,0.515,0.955); } 78.7879% { d: path('M1,-10L3,-10C3.55228,-10,4,-9.55228,4,-9L4,-7C4,-6.44772,3.55228,-6,3,-6L1,-6C0.447715,-6,0,-6.44772,0,-7L0,-9C0,-9.55228,0.447715,-10,1,-10Z'); } 100% { d: path('M1,-10L3,-10C3.55228,-10,4,-9.55228,4,-9L4,-7C4,-6.44772,3.55228,-6,3,-6L1,-6C0.447715,-6,0,-6.44772,0,-7L0,-9C0,-9.55228,0.447715,-10,1,-10Z'); } }
@keyframes a8_d { 0% { d: path('M1,0L3,0C3.55228,0,4,0.447715,4,1L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,1C0,0.447715,0.447715,0,1,0Z'); } 66.6667% { d: path('M1,0L3,0C3.55228,0,4,0.447715,4,1L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,1C0,0.447715,0.447715,0,1,0Z'); animation-timing-function: cubic-bezier(0.455,0.03,0.515,0.955); } 72.7273% { d: path('M1,-15L3,-15C3.55228,-15,4,-14.5523,4,-14L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,-14C0,-14.5523,0.447715,-15,1,-15Z'); animation-timing-function: cubic-bezier(0.455,0.03,0.515,0.955); } 78.7879% { d: path('M1,-10L3,-10C3.55228,-10,4,-9.55228,4,-9L4,-7C4,-6.44772,3.55228,-6,3,-6L1,-6C0.447715,-6,0,-6.44772,0,-7L0,-9C0,-9.55228,0.447715,-10,1,-10Z'); } 100% { d: path('M1,-10L3,-10C3.55228,-10,4,-9.55228,4,-9L4,-7C4,-6.44772,3.55228,-6,3,-6L1,-6C0.447715,-6,0,-6.44772,0,-7L0,-9C0,-9.55228,0.447715,-10,1,-10Z'); } }
@keyframes helper-drag-boomleftbottom_o { 0% { opacity: 0; } 66.6667% { opacity: 0; } 69.697% { opacity: 1; } 75.7576% { opacity: 1; } 78.7879% { opacity: 0; } 100% { opacity: 0; } }
@keyframes a9_d { 0% { d: path('M1,0L3,0C3.55228,0,4,0.447715,4,1L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,1C0,0.447715,0.447715,0,1,0Z'); } 66.6667% { d: path('M1,0L3,0C3.55228,0,4,0.447715,4,1L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,1C0,0.447715,0.447715,0,1,0Z'); animation-timing-function: cubic-bezier(0.455,0.03,0.515,0.955); } 72.7273% { d: path('M1,-15L3,-15C3.55228,-15,4,-14.5523,4,-14L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,-14C0,-14.5523,0.447715,-15,1,-15Z'); animation-timing-function: cubic-bezier(0.455,0.03,0.515,0.955); } 78.7879% { d: path('M1,-10L3,-10C3.55228,-10,4,-9.55228,4,-9L4,-7C4,-6.44772,3.55228,-6,3,-6L1,-6C0.447715,-6,0,-6.44772,0,-7L0,-9C0,-9.55228,0.447715,-10,1,-10Z'); } 100% { d: path('M1,-10L3,-10C3.55228,-10,4,-9.55228,4,-9L4,-7C4,-6.44772,3.55228,-6,3,-6L1,-6C0.447715,-6,0,-6.44772,0,-7L0,-9C0,-9.55228,0.447715,-10,1,-10Z'); } }
@keyframes a10_d { 0% { d: path('M1,0L3,0C3.55228,0,4,0.447715,4,1L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,1C0,0.447715,0.447715,0,1,0Z'); } 66.6667% { d: path('M1,0L3,0C3.55228,0,4,0.447715,4,1L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,1C0,0.447715,0.447715,0,1,0Z'); animation-timing-function: cubic-bezier(0.455,0.03,0.515,0.955); } 72.7273% { d: path('M1,-15L3,-15C3.55228,-15,4,-14.5523,4,-14L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,-14C0,-14.5523,0.447715,-15,1,-15Z'); animation-timing-function: cubic-bezier(0.455,0.03,0.515,0.955); } 78.7879% { d: path('M1,-10L3,-10C3.55228,-10,4,-9.55228,4,-9L4,-7C4,-6.44772,3.55228,-6,3,-6L1,-6C0.447715,-6,0,-6.44772,0,-7L0,-9C0,-9.55228,0.447715,-10,1,-10Z'); } 100% { d: path('M1,-10L3,-10C3.55228,-10,4,-9.55228,4,-9L4,-7C4,-6.44772,3.55228,-6,3,-6L1,-6C0.447715,-6,0,-6.44772,0,-7L0,-9C0,-9.55228,0.447715,-10,1,-10Z'); } }
@keyframes a11_d { 0% { d: path('M1,0L3,0C3.55228,0,4,0.447715,4,1L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,1C0,0.447715,0.447715,0,1,0Z'); } 66.6667% { d: path('M1,0L3,0C3.55228,0,4,0.447715,4,1L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,1C0,0.447715,0.447715,0,1,0Z'); animation-timing-function: cubic-bezier(0.455,0.03,0.515,0.955); } 72.7273% { d: path('M1,-15L3,-15C3.55228,-15,4,-14.5523,4,-14L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,-14C0,-14.5523,0.447715,-15,1,-15Z'); animation-timing-function: cubic-bezier(0.455,0.03,0.515,0.955); } 78.7879% { d: path('M1,-10L3,-10C3.55228,-10,4,-9.55228,4,-9L4,-7C4,-6.44772,3.55228,-6,3,-6L1,-6C0.447715,-6,0,-6.44772,0,-7L0,-9C0,-9.55228,0.447715,-10,1,-10Z'); } 100% { d: path('M1,-10L3,-10C3.55228,-10,4,-9.55228,4,-9L4,-7C4,-6.44772,3.55228,-6,3,-6L1,-6C0.447715,-6,0,-6.44772,0,-7L0,-9C0,-9.55228,0.447715,-10,1,-10Z'); } }
    </style>
    <rect id="helper-drag-leftrec" width="260" height="600" fill="#eeeeee" stroke="none" fill-rule="evenodd" rx="12" transform="translate(97.4763,336) translate(-27.4763,-124)"/>
    <rect id="helper-drag-rightrec" width="580" height="600" fill="#eeeeee" stroke="none" fill-rule="evenodd" rx="12" transform="translate(443.476,336) translate(-69.5,-124)"/>
    <rect id="helper-drag-rec" width="210" height="100" fill="#ffffff" rx="10" stroke="none" style="transform: translate(-111.034px,-48.1565px); animation: 3.3s linear infinite both helper-drag-rec_w, 3.3s linear infinite both helper-drag-rec_h, 3.3s linear infinite both helper-drag-rec_mo; offset-path: path('M206.034,298.157L205.034,298.157C481.262,280.399,662.717,480.353,670.01,510.157L505.01,280.552L515.01,290.157L515.01,290.157'); offset-rotate: 0deg;"/>
    <g id="helper-drag-mouse" fill="#000000" fill-rule="nonzero" opacity="1" style="transform: scale(2,2) translate(-14px,-14px); animation: 3.3s linear infinite both helper-drag-mouse_mo; offset-path: path('M200,613L200,290L200,300L200,300C371.375,309.667,501.439,327.173,663.976,512L663.976,512'); offset-rotate: 0deg;">
        <path id="helper-drag-mouse-path" d="M27.9059,13.8023L23.4402,10.278C23.364,10.218,23.2602,10.2069,23.173,10.2494C23.0858,10.2919,23.0305,10.3805,23.0308,10.4775L23.0308,12.7418L15.2614,12.7418L15.2614,4.9723L17.5292,4.9723C17.7392,4.9723,17.8582,4.72732,17.7287,4.56283L14.201,0.0971035C14.1537,0.0358608,14.0806,0,14.0032,0C13.9258,0,13.8528,0.0358608,13.8055,0.0971035L10.2778,4.56283C10.2178,4.63907,10.2067,4.74288,10.2492,4.83009C10.2917,4.91729,10.3803,4.97254,10.4773,4.9723L12.7416,4.9723L12.7416,12.7418L4.97221,12.7418L4.97221,10.474C4.97221,10.264,4.72723,10.145,4.56274,10.2745L0.0971017,13.8023C0.0358601,13.8496,0,13.9226,0,14C0,14.0774,0.0358601,14.1504,0.0971017,14.1977L4.55924,17.7255C4.72373,17.855,4.96871,17.7395,4.96871,17.526L4.96871,15.2617L12.7381,15.2617L12.7381,23.0312L10.4703,23.0312C10.2603,23.0312,10.1413,23.2762,10.2708,23.4407L13.7985,27.9029C13.9,28.0324,14.096,28.0324,14.194,27.9029L17.7217,23.4407C17.8512,23.2762,17.7357,23.0312,17.5222,23.0312L15.2614,23.0312L15.2614,15.2617L23.0308,15.2617L23.0308,17.5295C23.0308,17.7395,23.2757,17.8585,23.4402,17.729L27.9024,14.2012C27.9634,14.1533,27.9993,14.0802,28,14.0026C28.0007,13.925,27.966,13.8513,27.9059,13.8023L27.9059,13.8023Z" transform="translate(14,14) translate(-14,-14)"/>
    </g>
    <g id="helper-drag-boomrightup" opacity="0" transform="translate(985.577,207.1) scale(1.3,1.3) translate(-13,-13)" style="animation: 3.3s linear infinite both helper-drag-boomrightup_o;">
        <path fill="#b6b6b6" opacity="1" stroke="none" d="M1,0L3,0C3.55228,0,4,0.447715,4,1L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,1C0,0.447715,0.447715,0,1,0Z" transform="translate(-15.4435,22) scale(2,2) translate(-2,-14)" style="animation: 3.3s linear infinite both a0_d;"/>
        <path fill="#b6b6b6" opacity="1" stroke="none" d="M1,0L3,0C3.55228,0,4,0.447715,4,1L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,1C0,0.447715,0.447715,0,1,0Z" transform="translate(-15.4435,22) rotate(45) scale(2,2) translate(-2,-14)" style="animation: 3.3s linear infinite both a1_d;"/>
        <path fill="#b6b6b6" opacity="1" stroke="none" d="M1,0L3,0C3.55228,0,4,0.447715,4,1L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,1C0,0.447715,0.447715,0,1,0Z" transform="translate(-15.4435,22) rotate(90) scale(2,2) translate(-2,-14)" style="animation: 3.3s linear infinite both a2_d;"/>
    </g>
    <g id="helper-drag-boomleftup" opacity="0" transform="translate(367.253,188.323) rotate(-90) scale(1.3,1.3) translate(-13,-13)" style="animation: 3.3s linear infinite both helper-drag-boomleftup_o;">
        <path fill="#b6b6b6" opacity="1" stroke="none" d="M1,0L3,0C3.55228,0,4,0.447715,4,1L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,1C0,0.447715,0.447715,0,1,0Z" transform="translate(-15.4435,22) scale(2,2) translate(-2,-14)" style="animation: 3.3s linear infinite both a3_d;"/>
        <path fill="#b6b6b6" opacity="1" stroke="none" d="M1,0L3,0C3.55228,0,4,0.447715,4,1L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,1C0,0.447715,0.447715,0,1,0Z" transform="translate(-15.4435,22) rotate(45) scale(2,2) translate(-2,-14)" style="animation: 3.3s linear infinite both a4_d;"/>
        <path fill="#b6b6b6" opacity="1" stroke="none" d="M1,0L3,0C3.55228,0,4,0.447715,4,1L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,1C0,0.447715,0.447715,0,1,0Z" transform="translate(-15.4435,22) rotate(90) scale(2,2) translate(-2,-14)" style="animation: 3.3s linear infinite both a5_d;"/>
    </g>
    <g id="helper-drag-boomrightbottom" opacity="0" transform="translate(960.3,845.127) rotate(90) scale(1.3,1.3) translate(-13,-13)" style="animation: 3.3s linear infinite both helper-drag-boomrightbottom_o;">
        <path fill="#b6b6b6" opacity="1" stroke="none" d="M1,0L3,0C3.55228,0,4,0.447715,4,1L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,1C0,0.447715,0.447715,0,1,0Z" transform="translate(-15.4435,22) scale(2,2) translate(-2,-14)" style="animation: 3.3s linear infinite both a6_d;"/>
        <path fill="#b6b6b6" opacity="1" stroke="none" d="M1,0L3,0C3.55228,0,4,0.447715,4,1L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,1C0,0.447715,0.447715,0,1,0Z" transform="translate(-15.4435,22) rotate(45) scale(2,2) translate(-2,-14)" style="animation: 3.3s linear infinite both a7_d;"/>
        <path fill="#b6b6b6" opacity="1" stroke="none" d="M1,0L3,0C3.55228,0,4,0.447715,4,1L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,1C0,0.447715,0.447715,0,1,0Z" transform="translate(-15.4435,22) rotate(90) scale(2,2) translate(-2,-14)" style="animation: 3.3s linear infinite both a8_d;"/>
    </g>
    <g id="helper-drag-boomleftbottom" opacity="0" transform="translate(335.477,819.85) rotate(-180) scale(1.3,1.3) translate(-13,-13)" style="animation: 3.3s linear infinite both helper-drag-boomleftbottom_o;">
        <path fill="#b6b6b6" opacity="1" stroke="none" d="M1,0L3,0C3.55228,0,4,0.447715,4,1L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,1C0,0.447715,0.447715,0,1,0Z" transform="translate(-15.4435,22) scale(2,2) translate(-2,-14)" style="animation: 3.3s linear infinite both a9_d;"/>
        <path fill="#b6b6b6" opacity="1" stroke="none" d="M1,0L3,0C3.55228,0,4,0.447715,4,1L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,1C0,0.447715,0.447715,0,1,0Z" transform="translate(-15.4435,22) rotate(45) scale(2,2) translate(-2,-14)" style="animation: 3.3s linear infinite both a10_d;"/>
        <path fill="#b6b6b6" opacity="1" stroke="none" d="M1,0L3,0C3.55228,0,4,0.447715,4,1L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,1C0,0.447715,0.447715,0,1,0Z" transform="translate(-15.4435,22) rotate(90) scale(2,2) translate(-2,-14)" style="animation: 3.3s linear infinite both a11_d;"/>
    </g>
`,
      }}
    />
  ),
  dark: () => (
    <svg
      viewBox="0 0 1024 1024"
      dangerouslySetInnerHTML={{
        __html: `
        <style>
        @keyframes helper-drag-rec_w { 0% { width: 210px; } 54.5455% { width: 210px; animation-timing-function: cubic-bezier(0.904,0,0.726,0.668); } 66.6667% { width: 540px; animation-timing-function: cubic-bezier(0.214,0.499,0.52,0.867); } 72.7273% { width: 520px; } 100% { width: 520px; } }
        @keyframes helper-drag-rec_h { 0% { height: 100px; } 54.5455% { height: 100px; animation-timing-function: cubic-bezier(0.904,0,0.726,0.668); } 66.6667% { height: 560px; animation-timing-function: cubic-bezier(0.214,0.499,0.52,0.867); } 72.7273% { height: 540px; } 100% { height: 540px; } }
        @keyframes helper-drag-rec_mo { 0% { offset-distance: 0%; } 33.3333% { offset-distance: 0.12%; animation-timing-function: cubic-bezier(0.62,0.079,0.616,1); } 54.5455% { offset-distance: 64.297%; animation-timing-function: cubic-bezier(0.904,0,0.726,0.668); } 66.6667% { offset-distance: 98.331%; animation-timing-function: cubic-bezier(0.214,0.499,0.52,0.867); } 72.7273% { offset-distance: 100%; } 100% { offset-distance: 100%; } }
        @keyframes helper-drag-mouse_mo { 0% { offset-distance: 0%; animation-timing-function: cubic-bezier(0.69,0.145,0.37,1); } 18.1818% { offset-distance: 37.49%; } 24.2424% { offset-distance: 38.651%; } 33.3333% { offset-distance: 38.651%; animation-timing-function: cubic-bezier(0.62,0.079,0.616,1); } 54.5455% { offset-distance: 100%; } 100% { offset-distance: 100%; } }
        @keyframes helper-drag-boomrightup_o { 0% { opacity: 0; } 66.6667% { opacity: 0; } 69.697% { opacity: 1; } 75.7576% { opacity: 1; } 78.7879% { opacity: 0; } 100% { opacity: 0; } }
        @keyframes a0_d { 0% { d: path('M1,0L3,0C3.55228,0,4,0.447715,4,1L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,1C0,0.447715,0.447715,0,1,0Z'); } 66.6667% { d: path('M1,0L3,0C3.55228,0,4,0.447715,4,1L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,1C0,0.447715,0.447715,0,1,0Z'); animation-timing-function: cubic-bezier(0.455,0.03,0.515,0.955); } 72.7273% { d: path('M1,-15L3,-15C3.55228,-15,4,-14.5523,4,-14L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,-14C0,-14.5523,0.447715,-15,1,-15Z'); animation-timing-function: cubic-bezier(0.455,0.03,0.515,0.955); } 78.7879% { d: path('M1,-10L3,-10C3.55228,-10,4,-9.55228,4,-9L4,-7C4,-6.44772,3.55228,-6,3,-6L1,-6C0.447715,-6,0,-6.44772,0,-7L0,-9C0,-9.55228,0.447715,-10,1,-10Z'); } 100% { d: path('M1,-10L3,-10C3.55228,-10,4,-9.55228,4,-9L4,-7C4,-6.44772,3.55228,-6,3,-6L1,-6C0.447715,-6,0,-6.44772,0,-7L0,-9C0,-9.55228,0.447715,-10,1,-10Z'); } }
        @keyframes a1_d { 0% { d: path('M1,0L3,0C3.55228,0,4,0.447715,4,1L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,1C0,0.447715,0.447715,0,1,0Z'); } 66.6667% { d: path('M1,0L3,0C3.55228,0,4,0.447715,4,1L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,1C0,0.447715,0.447715,0,1,0Z'); animation-timing-function: cubic-bezier(0.455,0.03,0.515,0.955); } 72.7273% { d: path('M1,-15L3,-15C3.55228,-15,4,-14.5523,4,-14L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,-14C0,-14.5523,0.447715,-15,1,-15Z'); animation-timing-function: cubic-bezier(0.455,0.03,0.515,0.955); } 78.7879% { d: path('M1,-10L3,-10C3.55228,-10,4,-9.55228,4,-9L4,-7C4,-6.44772,3.55228,-6,3,-6L1,-6C0.447715,-6,0,-6.44772,0,-7L0,-9C0,-9.55228,0.447715,-10,1,-10Z'); } 100% { d: path('M1,-10L3,-10C3.55228,-10,4,-9.55228,4,-9L4,-7C4,-6.44772,3.55228,-6,3,-6L1,-6C0.447715,-6,0,-6.44772,0,-7L0,-9C0,-9.55228,0.447715,-10,1,-10Z'); } }
        @keyframes a2_d { 0% { d: path('M1,0L3,0C3.55228,0,4,0.447715,4,1L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,1C0,0.447715,0.447715,0,1,0Z'); } 66.6667% { d: path('M1,0L3,0C3.55228,0,4,0.447715,4,1L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,1C0,0.447715,0.447715,0,1,0Z'); animation-timing-function: cubic-bezier(0.455,0.03,0.515,0.955); } 72.7273% { d: path('M1,-15L3,-15C3.55228,-15,4,-14.5523,4,-14L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,-14C0,-14.5523,0.447715,-15,1,-15Z'); animation-timing-function: cubic-bezier(0.455,0.03,0.515,0.955); } 78.7879% { d: path('M1,-10L3,-10C3.55228,-10,4,-9.55228,4,-9L4,-7C4,-6.44772,3.55228,-6,3,-6L1,-6C0.447715,-6,0,-6.44772,0,-7L0,-9C0,-9.55228,0.447715,-10,1,-10Z'); } 100% { d: path('M1,-10L3,-10C3.55228,-10,4,-9.55228,4,-9L4,-7C4,-6.44772,3.55228,-6,3,-6L1,-6C0.447715,-6,0,-6.44772,0,-7L0,-9C0,-9.55228,0.447715,-10,1,-10Z'); } }
        @keyframes helper-drag-boomleftup_o { 0% { opacity: 0; } 66.6667% { opacity: 0; } 69.697% { opacity: 1; } 75.7576% { opacity: 1; } 78.7879% { opacity: 0; } 100% { opacity: 0; } }
        @keyframes a3_d { 0% { d: path('M1,0L3,0C3.55228,0,4,0.447715,4,1L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,1C0,0.447715,0.447715,0,1,0Z'); } 66.6667% { d: path('M1,0L3,0C3.55228,0,4,0.447715,4,1L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,1C0,0.447715,0.447715,0,1,0Z'); animation-timing-function: cubic-bezier(0.455,0.03,0.515,0.955); } 72.7273% { d: path('M1,-15L3,-15C3.55228,-15,4,-14.5523,4,-14L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,-14C0,-14.5523,0.447715,-15,1,-15Z'); animation-timing-function: cubic-bezier(0.455,0.03,0.515,0.955); } 78.7879% { d: path('M1,-10L3,-10C3.55228,-10,4,-9.55228,4,-9L4,-7C4,-6.44772,3.55228,-6,3,-6L1,-6C0.447715,-6,0,-6.44772,0,-7L0,-9C0,-9.55228,0.447715,-10,1,-10Z'); } 100% { d: path('M1,-10L3,-10C3.55228,-10,4,-9.55228,4,-9L4,-7C4,-6.44772,3.55228,-6,3,-6L1,-6C0.447715,-6,0,-6.44772,0,-7L0,-9C0,-9.55228,0.447715,-10,1,-10Z'); } }
        @keyframes a4_d { 0% { d: path('M1,0L3,0C3.55228,0,4,0.447715,4,1L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,1C0,0.447715,0.447715,0,1,0Z'); } 66.6667% { d: path('M1,0L3,0C3.55228,0,4,0.447715,4,1L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,1C0,0.447715,0.447715,0,1,0Z'); animation-timing-function: cubic-bezier(0.455,0.03,0.515,0.955); } 72.7273% { d: path('M1,-15L3,-15C3.55228,-15,4,-14.5523,4,-14L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,-14C0,-14.5523,0.447715,-15,1,-15Z'); animation-timing-function: cubic-bezier(0.455,0.03,0.515,0.955); } 78.7879% { d: path('M1,-10L3,-10C3.55228,-10,4,-9.55228,4,-9L4,-7C4,-6.44772,3.55228,-6,3,-6L1,-6C0.447715,-6,0,-6.44772,0,-7L0,-9C0,-9.55228,0.447715,-10,1,-10Z'); } 100% { d: path('M1,-10L3,-10C3.55228,-10,4,-9.55228,4,-9L4,-7C4,-6.44772,3.55228,-6,3,-6L1,-6C0.447715,-6,0,-6.44772,0,-7L0,-9C0,-9.55228,0.447715,-10,1,-10Z'); } }
        @keyframes a5_d { 0% { d: path('M1,0L3,0C3.55228,0,4,0.447715,4,1L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,1C0,0.447715,0.447715,0,1,0Z'); } 66.6667% { d: path('M1,0L3,0C3.55228,0,4,0.447715,4,1L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,1C0,0.447715,0.447715,0,1,0Z'); animation-timing-function: cubic-bezier(0.455,0.03,0.515,0.955); } 72.7273% { d: path('M1,-15L3,-15C3.55228,-15,4,-14.5523,4,-14L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,-14C0,-14.5523,0.447715,-15,1,-15Z'); animation-timing-function: cubic-bezier(0.455,0.03,0.515,0.955); } 78.7879% { d: path('M1,-10L3,-10C3.55228,-10,4,-9.55228,4,-9L4,-7C4,-6.44772,3.55228,-6,3,-6L1,-6C0.447715,-6,0,-6.44772,0,-7L0,-9C0,-9.55228,0.447715,-10,1,-10Z'); } 100% { d: path('M1,-10L3,-10C3.55228,-10,4,-9.55228,4,-9L4,-7C4,-6.44772,3.55228,-6,3,-6L1,-6C0.447715,-6,0,-6.44772,0,-7L0,-9C0,-9.55228,0.447715,-10,1,-10Z'); } }
        @keyframes helper-drag-boomrightbottom_o { 0% { opacity: 0; } 66.6667% { opacity: 0; } 69.697% { opacity: 1; } 75.7576% { opacity: 1; } 78.7879% { opacity: 0; } 100% { opacity: 0; } }
        @keyframes a6_d { 0% { d: path('M1,0L3,0C3.55228,0,4,0.447715,4,1L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,1C0,0.447715,0.447715,0,1,0Z'); } 66.6667% { d: path('M1,0L3,0C3.55228,0,4,0.447715,4,1L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,1C0,0.447715,0.447715,0,1,0Z'); animation-timing-function: cubic-bezier(0.455,0.03,0.515,0.955); } 72.7273% { d: path('M1,-15L3,-15C3.55228,-15,4,-14.5523,4,-14L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,-14C0,-14.5523,0.447715,-15,1,-15Z'); animation-timing-function: cubic-bezier(0.455,0.03,0.515,0.955); } 78.7879% { d: path('M1,-10L3,-10C3.55228,-10,4,-9.55228,4,-9L4,-7C4,-6.44772,3.55228,-6,3,-6L1,-6C0.447715,-6,0,-6.44772,0,-7L0,-9C0,-9.55228,0.447715,-10,1,-10Z'); } 100% { d: path('M1,-10L3,-10C3.55228,-10,4,-9.55228,4,-9L4,-7C4,-6.44772,3.55228,-6,3,-6L1,-6C0.447715,-6,0,-6.44772,0,-7L0,-9C0,-9.55228,0.447715,-10,1,-10Z'); } }
        @keyframes a7_d { 0% { d: path('M1,0L3,0C3.55228,0,4,0.447715,4,1L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,1C0,0.447715,0.447715,0,1,0Z'); } 66.6667% { d: path('M1,0L3,0C3.55228,0,4,0.447715,4,1L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,1C0,0.447715,0.447715,0,1,0Z'); animation-timing-function: cubic-bezier(0.455,0.03,0.515,0.955); } 72.7273% { d: path('M1,-15L3,-15C3.55228,-15,4,-14.5523,4,-14L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,-14C0,-14.5523,0.447715,-15,1,-15Z'); animation-timing-function: cubic-bezier(0.455,0.03,0.515,0.955); } 78.7879% { d: path('M1,-10L3,-10C3.55228,-10,4,-9.55228,4,-9L4,-7C4,-6.44772,3.55228,-6,3,-6L1,-6C0.447715,-6,0,-6.44772,0,-7L0,-9C0,-9.55228,0.447715,-10,1,-10Z'); } 100% { d: path('M1,-10L3,-10C3.55228,-10,4,-9.55228,4,-9L4,-7C4,-6.44772,3.55228,-6,3,-6L1,-6C0.447715,-6,0,-6.44772,0,-7L0,-9C0,-9.55228,0.447715,-10,1,-10Z'); } }
        @keyframes a8_d { 0% { d: path('M1,0L3,0C3.55228,0,4,0.447715,4,1L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,1C0,0.447715,0.447715,0,1,0Z'); } 66.6667% { d: path('M1,0L3,0C3.55228,0,4,0.447715,4,1L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,1C0,0.447715,0.447715,0,1,0Z'); animation-timing-function: cubic-bezier(0.455,0.03,0.515,0.955); } 72.7273% { d: path('M1,-15L3,-15C3.55228,-15,4,-14.5523,4,-14L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,-14C0,-14.5523,0.447715,-15,1,-15Z'); animation-timing-function: cubic-bezier(0.455,0.03,0.515,0.955); } 78.7879% { d: path('M1,-10L3,-10C3.55228,-10,4,-9.55228,4,-9L4,-7C4,-6.44772,3.55228,-6,3,-6L1,-6C0.447715,-6,0,-6.44772,0,-7L0,-9C0,-9.55228,0.447715,-10,1,-10Z'); } 100% { d: path('M1,-10L3,-10C3.55228,-10,4,-9.55228,4,-9L4,-7C4,-6.44772,3.55228,-6,3,-6L1,-6C0.447715,-6,0,-6.44772,0,-7L0,-9C0,-9.55228,0.447715,-10,1,-10Z'); } }
        @keyframes helper-drag-boomleftbottom_o { 0% { opacity: 0; } 66.6667% { opacity: 0; } 69.697% { opacity: 1; } 75.7576% { opacity: 1; } 78.7879% { opacity: 0; } 100% { opacity: 0; } }
        @keyframes a9_d { 0% { d: path('M1,0L3,0C3.55228,0,4,0.447715,4,1L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,1C0,0.447715,0.447715,0,1,0Z'); } 66.6667% { d: path('M1,0L3,0C3.55228,0,4,0.447715,4,1L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,1C0,0.447715,0.447715,0,1,0Z'); animation-timing-function: cubic-bezier(0.455,0.03,0.515,0.955); } 72.7273% { d: path('M1,-15L3,-15C3.55228,-15,4,-14.5523,4,-14L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,-14C0,-14.5523,0.447715,-15,1,-15Z'); animation-timing-function: cubic-bezier(0.455,0.03,0.515,0.955); } 78.7879% { d: path('M1,-10L3,-10C3.55228,-10,4,-9.55228,4,-9L4,-7C4,-6.44772,3.55228,-6,3,-6L1,-6C0.447715,-6,0,-6.44772,0,-7L0,-9C0,-9.55228,0.447715,-10,1,-10Z'); } 100% { d: path('M1,-10L3,-10C3.55228,-10,4,-9.55228,4,-9L4,-7C4,-6.44772,3.55228,-6,3,-6L1,-6C0.447715,-6,0,-6.44772,0,-7L0,-9C0,-9.55228,0.447715,-10,1,-10Z'); } }
        @keyframes a10_d { 0% { d: path('M1,0L3,0C3.55228,0,4,0.447715,4,1L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,1C0,0.447715,0.447715,0,1,0Z'); } 66.6667% { d: path('M1,0L3,0C3.55228,0,4,0.447715,4,1L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,1C0,0.447715,0.447715,0,1,0Z'); animation-timing-function: cubic-bezier(0.455,0.03,0.515,0.955); } 72.7273% { d: path('M1,-15L3,-15C3.55228,-15,4,-14.5523,4,-14L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,-14C0,-14.5523,0.447715,-15,1,-15Z'); animation-timing-function: cubic-bezier(0.455,0.03,0.515,0.955); } 78.7879% { d: path('M1,-10L3,-10C3.55228,-10,4,-9.55228,4,-9L4,-7C4,-6.44772,3.55228,-6,3,-6L1,-6C0.447715,-6,0,-6.44772,0,-7L0,-9C0,-9.55228,0.447715,-10,1,-10Z'); } 100% { d: path('M1,-10L3,-10C3.55228,-10,4,-9.55228,4,-9L4,-7C4,-6.44772,3.55228,-6,3,-6L1,-6C0.447715,-6,0,-6.44772,0,-7L0,-9C0,-9.55228,0.447715,-10,1,-10Z'); } }
        @keyframes a11_d { 0% { d: path('M1,0L3,0C3.55228,0,4,0.447715,4,1L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,1C0,0.447715,0.447715,0,1,0Z'); } 66.6667% { d: path('M1,0L3,0C3.55228,0,4,0.447715,4,1L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,1C0,0.447715,0.447715,0,1,0Z'); animation-timing-function: cubic-bezier(0.455,0.03,0.515,0.955); } 72.7273% { d: path('M1,-15L3,-15C3.55228,-15,4,-14.5523,4,-14L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,-14C0,-14.5523,0.447715,-15,1,-15Z'); animation-timing-function: cubic-bezier(0.455,0.03,0.515,0.955); } 78.7879% { d: path('M1,-10L3,-10C3.55228,-10,4,-9.55228,4,-9L4,-7C4,-6.44772,3.55228,-6,3,-6L1,-6C0.447715,-6,0,-6.44772,0,-7L0,-9C0,-9.55228,0.447715,-10,1,-10Z'); } 100% { d: path('M1,-10L3,-10C3.55228,-10,4,-9.55228,4,-9L4,-7C4,-6.44772,3.55228,-6,3,-6L1,-6C0.447715,-6,0,-6.44772,0,-7L0,-9C0,-9.55228,0.447715,-10,1,-10Z'); } }
            </style>
            <rect id="helper-drag-leftrec" width="260" height="600" fill="#3b3b3b" stroke="none" fill-rule="evenodd" rx="12" transform="translate(97.4763,336) translate(-27.4763,-124)"/>
            <rect id="helper-drag-rightrec" width="580" height="600" fill="#3b3b3b" stroke="none" fill-rule="evenodd" rx="12" transform="translate(443.476,336) translate(-69.5,-124)"/>
            <rect id="helper-drag-rec" width="210" height="100" fill="#1a1a1a" rx="10" stroke="none" style="transform: translate(-111.034px,-48.1565px); animation: 3.3s linear infinite both helper-drag-rec_w, 3.3s linear infinite both helper-drag-rec_h, 3.3s linear infinite both helper-drag-rec_mo; offset-path: path('M206.034,298.157L205.034,298.157C481.262,280.399,662.717,480.353,670.01,510.157L505.01,280.552L515.01,290.157L515.01,290.157'); offset-rotate: 0deg;"/>
            <g id="helper-drag-mouse" fill="#000000" fill-rule="nonzero" opacity="1" style="transform: scale(2,2) translate(-14px,-14px); animation: 3.3s linear infinite both helper-drag-mouse_mo; offset-path: path('M200,613L200,290L200,300L200,300C371.375,309.667,501.439,327.173,663.976,512L663.976,512'); offset-rotate: 0deg;">
                <path id="helper-drag-mouse-path" d="M27.9059,13.8023L23.4402,10.278C23.364,10.218,23.2602,10.2069,23.173,10.2494C23.0858,10.2919,23.0305,10.3805,23.0308,10.4775L23.0308,12.7418L15.2614,12.7418L15.2614,4.9723L17.5292,4.9723C17.7392,4.9723,17.8582,4.72732,17.7287,4.56283L14.201,0.0971035C14.1537,0.0358608,14.0806,0,14.0032,0C13.9258,0,13.8528,0.0358608,13.8055,0.0971035L10.2778,4.56283C10.2178,4.63907,10.2067,4.74288,10.2492,4.83009C10.2917,4.91729,10.3803,4.97254,10.4773,4.9723L12.7416,4.9723L12.7416,12.7418L4.97221,12.7418L4.97221,10.474C4.97221,10.264,4.72723,10.145,4.56274,10.2745L0.0971017,13.8023C0.0358601,13.8496,0,13.9226,0,14C0,14.0774,0.0358601,14.1504,0.0971017,14.1977L4.55924,17.7255C4.72373,17.855,4.96871,17.7395,4.96871,17.526L4.96871,15.2617L12.7381,15.2617L12.7381,23.0312L10.4703,23.0312C10.2603,23.0312,10.1413,23.2762,10.2708,23.4407L13.7985,27.9029C13.9,28.0324,14.096,28.0324,14.194,27.9029L17.7217,23.4407C17.8512,23.2762,17.7357,23.0312,17.5222,23.0312L15.2614,23.0312L15.2614,15.2617L23.0308,15.2617L23.0308,17.5295C23.0308,17.7395,23.2757,17.8585,23.4402,17.729L27.9024,14.2012C27.9634,14.1533,27.9993,14.0802,28,14.0026C28.0007,13.925,27.966,13.8513,27.9059,13.8023L27.9059,13.8023Z" fill="#ffffff" transform="translate(14,14) translate(-14,-14)"/>
            </g>
            <g id="helper-drag-boomrightup" opacity="0" transform="translate(985.577,207.1) scale(1.3,1.3) translate(-13,-13)" style="animation: 3.3s linear infinite both helper-drag-boomrightup_o;">
                <path fill="#808080" opacity="1" stroke="none" d="M1,0L3,0C3.55228,0,4,0.447715,4,1L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,1C0,0.447715,0.447715,0,1,0Z" transform="translate(-15.4435,22) scale(2,2) translate(-2,-14)" style="animation: 3.3s linear infinite both a0_d;"/>
                <path fill="#808080" opacity="1" stroke="none" d="M1,0L3,0C3.55228,0,4,0.447715,4,1L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,1C0,0.447715,0.447715,0,1,0Z" transform="translate(-15.4435,22) rotate(45) scale(2,2) translate(-2,-14)" style="animation: 3.3s linear infinite both a1_d;"/>
                <path fill="#808080" opacity="1" stroke="none" d="M1,0L3,0C3.55228,0,4,0.447715,4,1L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,1C0,0.447715,0.447715,0,1,0Z" transform="translate(-15.4435,22) rotate(90) scale(2,2) translate(-2,-14)" style="animation: 3.3s linear infinite both a2_d;"/>
            </g>
            <g id="helper-drag-boomleftup" opacity="0" transform="translate(367.253,188.323) rotate(-90) scale(1.3,1.3) translate(-13,-13)" style="animation: 3.3s linear infinite both helper-drag-boomleftup_o;">
                <path fill="#808080" opacity="1" stroke="none" d="M1,0L3,0C3.55228,0,4,0.447715,4,1L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,1C0,0.447715,0.447715,0,1,0Z" transform="translate(-15.4435,22) scale(2,2) translate(-2,-14)" style="animation: 3.3s linear infinite both a3_d;"/>
                <path fill="#808080" opacity="1" stroke="none" d="M1,0L3,0C3.55228,0,4,0.447715,4,1L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,1C0,0.447715,0.447715,0,1,0Z" transform="translate(-15.4435,22) rotate(45) scale(2,2) translate(-2,-14)" style="animation: 3.3s linear infinite both a4_d;"/>
                <path fill="#808080" opacity="1" stroke="none" d="M1,0L3,0C3.55228,0,4,0.447715,4,1L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,1C0,0.447715,0.447715,0,1,0Z" transform="translate(-15.4435,22) rotate(90) scale(2,2) translate(-2,-14)" style="animation: 3.3s linear infinite both a5_d;"/>
            </g>
            <g id="helper-drag-boomrightbottom" opacity="0" transform="translate(960.3,845.127) rotate(90) scale(1.3,1.3) translate(-13,-13)" style="animation: 3.3s linear infinite both helper-drag-boomrightbottom_o;">
                <path fill="#808080" opacity="1" stroke="none" d="M1,0L3,0C3.55228,0,4,0.447715,4,1L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,1C0,0.447715,0.447715,0,1,0Z" transform="translate(-15.4435,22) scale(2,2) translate(-2,-14)" style="animation: 3.3s linear infinite both a6_d;"/>
                <path fill="#808080" opacity="1" stroke="none" d="M1,0L3,0C3.55228,0,4,0.447715,4,1L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,1C0,0.447715,0.447715,0,1,0Z" transform="translate(-15.4435,22) rotate(45) scale(2,2) translate(-2,-14)" style="animation: 3.3s linear infinite both a7_d;"/>
                <path fill="#808080" opacity="1" stroke="none" d="M1,0L3,0C3.55228,0,4,0.447715,4,1L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,1C0,0.447715,0.447715,0,1,0Z" transform="translate(-15.4435,22) rotate(90) scale(2,2) translate(-2,-14)" style="animation: 3.3s linear infinite both a8_d;"/>
            </g>
            <g id="helper-drag-boomleftbottom" opacity="0" transform="translate(335.477,819.85) rotate(-180) scale(1.3,1.3) translate(-13,-13)" style="animation: 3.3s linear infinite both helper-drag-boomleftbottom_o;">
                <path fill="#808080" opacity="1" stroke="none" d="M1,0L3,0C3.55228,0,4,0.447715,4,1L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,1C0,0.447715,0.447715,0,1,0Z" transform="translate(-15.4435,22) scale(2,2) translate(-2,-14)" style="animation: 3.3s linear infinite both a9_d;"/>
                <path fill="#808080" opacity="1" stroke="none" d="M1,0L3,0C3.55228,0,4,0.447715,4,1L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,1C0,0.447715,0.447715,0,1,0Z" transform="translate(-15.4435,22) rotate(45) scale(2,2) translate(-2,-14)" style="animation: 3.3s linear infinite both a10_d;"/>
                <path fill="#808080" opacity="1" stroke="none" d="M1,0L3,0C3.55228,0,4,0.447715,4,1L4,3C4,3.55228,3.55228,4,3,4L1,4C0.447715,4,0,3.55228,0,3L0,1C0,0.447715,0.447715,0,1,0Z" transform="translate(-15.4435,22) rotate(90) scale(2,2) translate(-2,-14)" style="animation: 3.3s linear infinite both a11_d;"/>
            </g>
        `,
      }}
    />
  ),
};

DragSourceAnimation.light.displayName = "LightCanvasEmpty";
DragSourceAnimation.dark.displayName = "DarkCanvasEmpty";
